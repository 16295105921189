$(document).ready(function() {
  // мобильное меню
  $('.js-hamburger').click(function() {
    $(this).toggleClass("active");
    $('.js-mob-user-menu').toggleClass('active');
  });

  $('.js-mob-user-menu .close').click(function() {
    $(this).parent().removeClass('active');
    $('.js-hamburger').toggleClass('active');
  });

  $('.js-profile').click(function() {
    $('.js-mob-menu').addClass('active');
  });

  $('.js-mob-menu .close').click(function() {
    $(this).parent().removeClass('active');
  });

  $('.js-profile-user').click(function() {
    $(this).next().toggleClass('profile-user-open');
  });

  $(document).mouseup(function(e) {
    var div = $('.profile-user-wrap');
    if (!div.is(e.target) && div.has(e.target).length === 0) {
      div.find('.js-mob-menu').removeClass('profile-user-open');
    }
  });

  $('.js-open-modal').click(function(e) {
    e.preventDefault();
    var data = $(this).attr('data-modal-id');
    console.log(data);
    $('#' + data).arcticmodal();
  });

  $('.js-link-filter').click(function() {
    $('.filters').slideToggle();
  });

  $('.js-link-filter-profile').click(function() {
    $('.filters-wrap').slideToggle();

    return false;
  });

  $('.js-link-chat').click(function() {
    $('.chat-block').slideToggle();
  });

  $('.js-select').find('.select-title').click(function() {
    $(this).parent().toggleClass('active');
  });

  $('.select-content-item').click(function() {
    var text = $(this).text();
    $(this).closest('.js-select').find('.select-title span').text(text);
    $(this).closest('.js-select').toggleClass('active');
  });

  $(document).mouseup(function(e) {
    var div = $('.select-content');
    if (!div.is(e.target) && div.has(e.target).length === 0) {
      div.closest('.js-select').removeClass('active');
    }
  });

  $('.js-dropdown').click(function() {
    $(this).closest('.filter-dropdown-item').toggleClass('close');
  });

  if ($('#creating-application-2').length > 0) {
    $('#creating-application-2').arcticmodal();
  }

  $('.datepicker').change(function() {
    var val = $(this).val();
    console.log(val);
  });

  $('.input-item .icon-close').click(function() {
    $(this).parent().find('input').val("");
    $(this).parent().removeClass('active');
  });

  $('.request-files-item .icon-more').click(function() {
    $(this).parent().find('.request-files-item-dropdown').show();
  });

  $(document).mouseup(function(e) {
    var div = $('.request-files-item-dropdown');
    if (!div.is(e.target) && div.has(e.target).length === 0) {
      div.hide();
    }
  });

  $('.js-notice-dropdown').click(function() {
    $('.history-request-header').addClass('notice-open');
  });

  $('.history-request-header .close, .history-request-header .btn-close').click(function() {
    $('.history-request-header').removeClass('notice-open');;
  });

  $('.js-controls-dropdown').click(function() {
    $(this).next().toggle();
  });

  $('.chat-controls .input').focus(function() {
    $(this).closest('.chat-controls').addClass('active');
  });

  // открытие попапов на странице popups.html
  $('#popups #creating-application').arcticmodal();
  $('#popups #creating-application-2').arcticmodal();
  $('#popups #creating-application-3').arcticmodal();
  $('#popups #note-delete').arcticmodal();
  $('#popups #choose-costs-center').arcticmodal();
  $('#popups #add-expenses').arcticmodal();
  $('#popups #add-expenses-2').arcticmodal();
  $('#popups #modal-oops').arcticmodal();
  $('#popups #modal-new').arcticmodal();
  $('#popups #modal-new-5').arcticmodal();
  $('#popups #add-reassignment').arcticmodal();
  $('#popups #modal-print').arcticmodal();

  $('.list-agreement-table-row.parent .td:first-child').click(function() {
    $(this).toggleClass('active');
    $(this).closest('.parent').find('.child').slideToggle('active');
  });
});
